// import GATracker from "./trackers/gaTracker";
import GA4Tracker from "./trackers/ga4Tracker";
import FQBTracker from "./trackers/fqbTracker";


export const getTracker = (trackerName, options) => {
    switch(trackerName) {
    case 'fbq':
        return new FQBTracker(options);
    case 'ga':
    case 'ga4':
        return new GA4Tracker(options);
    default:
        throw new Error(`Invalid tracker name: ${trackerName}`);
    }
}